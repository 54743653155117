/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";

// Images
import css from "@emotion/css";
import { keyframes } from "@emotion/core";
// import ImgBackgroundIntro from "../Images/Journal/ImgBackgroundIntro";
import JournalHandwritting from "../Animations/JournalHandwritting";
import TextAssistant24 from "../Base/text/TextAssistant24";
import VideoPopup from "../VideoPlayers/VideoPopup";
import NextPageArrow from "../Navigation/NextPageArrow";

// Video
import IntroVideo from "../../videos/anana-vestige-journal-video.mp4";
import VideoBackground from "../../videos/video-journal-intro.mp4";

// Images
import VideoCover from "../../images/journal/anana-vestige-journal-video-cover.png";
import VideoPLayerFullScreen from "../VideoPlayers/VideoPlayerFullScreen";
import { mediaQueries } from "../../configs/_configSite";

const ContainerMain = styled(Div100vh)`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: blue;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ContainerIntroText = styled.div`
  position: absolute;
  display: inline-table;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0;
  width: 700px;
  right: 15vw;
  margin: auto;
  top: 50vh;
  bottom: 0;
  animation: ${fadeIn} 0.5s ease-in 4s forwards;

  @media (max-width: ${mediaQueries.lg}px) {
    width: 500px;
    right: 10vw;
  }

  @media (max-width: ${mediaQueries.md}px) {
    max-width: 100%;
    width: 100%;
    right: initial;
    padding: 15px 30px;
  }
  @media (max-width: ${mediaQueries.sm}px) {
    margin-top: -100px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    margin-top: -200px;
  }
`;

export default () => {
  return (
    <ContainerMain>
      <JournalHandwritting />
      <ContainerIntroText>
        <TextAssistant24
          css={css`
            font-size: 16px !important;
          `}
        >
          These days, life marvels us with its power, beauty, and opulent grace
          expressed during tremendous tumultuous, and sometimes splendid
          situations. In the mist of life’s dramatic, theatrical moments, take
          this incredible opportunity to capture your thoughts, feelings, and
          learning ventures to write down pertinent temporaneous vestiges that
          linger in the aftermath of your circumstances. It is so cool how God,
          the holy one is crazy about wanting to travel with us, to explore and
          learn of His merciful nature in our life’s journey. Real, jampacked,
          impactful, intense, soft, calm and in the stillness of the hour,
          prayer time is always available for you to communicate with the
          Almighty and rest with the God of yesterday, today and tomorrow. In
          Matthew 6: 9-13, Jesus had just finished praying, His disciples were
          amazed on how magnificent Jesus is, they longed to be like Him.
        </TextAssistant24>
      </ContainerIntroText>
      <VideoPopup url={IntroVideo} image={VideoCover} />
      <NextPageArrow text="Product" />
      <VideoPLayerFullScreen video={VideoBackground} />
    </ContainerMain>
  );
};
