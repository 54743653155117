/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import AudioPlayer from "react-h5-audio-player";

// import { css } from "@emotion/core";
import styled from "@emotion/styled";

// CSS
import "../../styles/audio-player.css";
import "react-h5-audio-player/lib/styles.css";

// Audio Track
import AudioFile from "../../audio/journal-altin-rose.mp3";
import { mediaQueries } from "../../configs/_configSite";

const Container = styled.section`
  position: absolute;
  z-index: 1;
  bottom: 45px;
  left: 45px;

  * {
    color: #ffffff !important;
    font-family: Assistant !important;
  }

  .rhap_container {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 350px;
    height: 150px;
    padding: 15px;
  }

  .rhap_main-controls {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .rhap_play-pause-button,
  .rhap_volume-button {
    path {
      fill: #d7b401;
    }
  }

  .rhap_additional-controls {
    display: none;
  }

  .rhap_progress-indicator,
  .rhap_progress-filled,
  .rhap_volume-indicator,
  .rhap_volume-filled {
    background: #d7b401 !important;
  }

  @media (max-width: ${mediaQueries.md}px) {
    display: none;
  }
`;

const Title = styled.p`
  font-size: 24px;
  font-family: Assistant;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 100;
  font-family: Assistant;
  margin: 0;
  padding: 0;
`;

const MusicLink = styled.a`
  position: absolute;
  bottom: 45px;
  bottom: 28px;
  font-size: 14px;
  font-weight: 100;
  font-family: Assistant;
  margin: 0;
  padding: 0;
`;

export default ({
  title = "Title",
  subtitle = "Subtitle",
  link = "https://apple.com"
}) => {
  return (
    <Container>
      <AudioPlayer
        header={
          <>
            <SubTitle>{subtitle}</SubTitle>
            <Title>{title}</Title>
          </>
        }
        footer={
          <>
            <MusicLink href={link}>Purchase Track on iTunes</MusicLink>
          </>
        }
        loop
        src={AudioFile}
        controls
        layout="stacked"
        showFilledProgress
        showFilledVolume
        showJumpControls={false}
        autoPlay
      />
    </Container>
  );
};
