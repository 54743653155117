import React from "react";

// Components
import Layout from "../components/Layouts/Layout";
import SEO from "../components/Utilities/SEO";
import SliderVertical from "../components/Sliders/SliderVertical";
import { ProviderEntryUI } from "../contexts/context-entry-ui";
import SectionJournalIntro from "../components/Sections/SectionJournalIntro";
import SectionJournalProducts from "../components/Sections/SectionJournalProducts";
// import ImgSlideTransitionJournal from "../components/Images/ImgSlideTransitionJournal";
import ImgSlideTransitionJournal from "../components/Images/ImgSlideTransitionJournal";
import FeaturesAudioPlayer from "../components/Features/FeaturesAudioPlayer";

const slides = [<SectionJournalIntro />, <SectionJournalProducts />];

const JournalPage = () => {
  return (
    <Layout scrollLock>
      <SEO
        title="Know Believe Fulfill | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <ProviderEntryUI>
        <FeaturesAudioPlayer />
        <SliderVertical
          slides={slides}
          slideImage={<ImgSlideTransitionJournal />}
        />
      </ProviderEntryUI>
    </Layout>
  );
};

export default JournalPage;
