import React from "react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";
import FeaturesJournalProduct from "../Features/FeaturesJournalProduct";
import ImgBackgroundProduct from "../Images/Journal/ImgBackgroundProduct";

// Config
// import { css } from "@emotion/core";
// import { colors, contact, mediaQueries } from "../../configs/_configSite";

const ContainerMain = styled(Div100vh)`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: green;
`;

export default () => {
  return (
    <ContainerMain>
      <ImgBackgroundProduct />
      <FeaturesJournalProduct />
    </ContainerMain>
  );
};
