import React from "react";
import { PropTypes } from "prop-types";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link as GLink } from "gatsby";
import _configBase from "../../../configs/_configBase";
import { fonts } from "../../../configs/_configSite";

// Images
import ImgLeafPatternButtonSmall from "../../Images/Features/ImgLeafPatternButtonSmall";

const sharedCSS = `
${_configBase}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #00bea7;
  height: 70px;
  width: 195px;

  text-align: center;
  color: #ffffff;
  font-family: ${fonts.secondary};
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;

  transition: 0.2s;
  outline: none;
  cursor: pointer;

  &:hover {
    background: #000000;
  }

  > span {
    position: relative;
  }
`;

// Styles
const LinkInt = styled(GLink)`
  ${sharedCSS}
`;

const LinkExt = styled.a`
  ${sharedCSS}
`;

const LinkLeafInt = ({ children, to, cssProp }) => (
  <LinkInt
    to={to}
    css={css`
      ${cssProp}
    `}
  >
    <ImgLeafPatternButtonSmall />
    <span>{children}</span>
  </LinkInt>
);

LinkLeafInt.defaultProps = {
  children: "Test",
  to: "/",
  cssProp: ""
};

LinkLeafInt.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  cssProp: PropTypes.string
};

const LinkLeafExt = ({ children, href, cssProp }) => (
  <LinkExt
    href={href}
    css={css`
      ${cssProp}
    `}
  >
    <ImgLeafPatternButtonSmall />
    <span>{children}</span>
  </LinkExt>
);

LinkLeafExt.defaultProps = {
  children: "Test",
  href: "/",
  cssProp: ""
};

LinkLeafExt.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  cssProp: PropTypes.string
};

export { LinkLeafInt, LinkLeafExt };
