/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from "react";

// import { css } from "@emotion/core";
import styled from "@emotion/styled";

// CSS
import "../../styles/audio-player.css";
import "react-h5-audio-player/lib/styles.css";
import css from "@emotion/css";
import { fonts, mediaQueries } from "../../configs/_configSite";
import ImgProduct from "../Images/Journal/ImgProductBackground";
import { LinkLeafExt } from "../Base/links/LinkLeaf";
import ImgProductJournal1 from "../Images/Journal/products/ImgProductJournal1";

const Container = styled.section`
  position: relative;
  display: flex;
  z-index: 1;
  height: 400px;
  top: 25vh;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  max-width: ${mediaQueries.lg}px;

  @media (max-width: ${mediaQueries.lg}px) {
    max-width: ${mediaQueries.md}px;
  }
  @media (max-width: ${mediaQueries.md}px) {
    max-width: ${mediaQueries.sm}px;
  }
  @media (max-width: ${mediaQueries.sm}px) {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    height: 800px;
    top: 7.5vh;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  background: transparent;

  height: 400px;
  width: 300px;

  @media (max-width: ${mediaQueries.sm}px) {
    height: 300px;
    width: 200px;
    margin: 0 auto;
  }

  @media (max-width: ${mediaQueries.xs}px) {
    height: 275px;
    width: 200px;
    margin: 0 auto;
    padding: 15px 30px 15px 15px;
  }
`;

const ProductContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  background: transparent;
  height: 100%;
  width: 70%;
  padding: 15px 60px;

  @media (max-width: ${mediaQueries.sm}px) {
    max-width: 100%;
    width: 100%;
    height: 400px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    height: 425px;
  }
`;

const ProductTitle = styled.h1`
  position: relative;
  font-size: 34px;
  font-weight: 300;
  font-family: ${fonts.pentiary};
  color: black;
`;

const ProductDescription = styled.p`
  position: relative;
  font-family: Assistant;
  color: black;
  height: 150px;
  overflow: auto;
`;

const Price = styled.span`
  color: black;
  font-size: 28px;
  font-family: ${fonts.pentiary};
  margin-right: 30px;
`;

const data = [
  {
    category: "Prayer",
    images: [<ImgProductJournal1 />],
    title: "Prayer Journal",
    description:
      "Description: They might have used a journal likened to this Tuscon Ivory Notebook made by The Castelli Family, Bergamo, Italy. It has impeccable dimensions (medium size 130x210mm), and expertly designed to keep temporaneous notetaking close at hand. It is Anana Vestige's chosen contemporary forest green colored, 240 ruled paged journal armed with the same color elastic band closure, and pen loop to hold the writing pen or pencil. It is fashioned with a beautiful classic gold ribbon strand, hung to keep you in place. It is featured with chosen ivory FSC ecological paper sourced from sustainable forests, prepared with elegant, rounded corner edges, all enclosed in a soft felt material. It is also crafted with a back pocket to carry additional notes, pictures, sketches, small memorabilia and things: to share His teaching with friends or family or just to reflect or study about His mercy and grace.",
    price: 39.99,
    link: ""
  }
];

export default () => {
  return (
    <Container>
      {data.map(p => (
        <>
          <ImgContainer>{p.images}</ImgContainer>
          <ProductContainer>
            <ImgProduct />
            <ProductTitle>{p.title}</ProductTitle>
            <ProductDescription>{p.description}</ProductDescription>
            <div
              css={css`
                position: relative;
                text-align: right;
                @media (max-width: ${mediaQueries.xxs}px) {
                  text-align: center;
                  > * {
                    margin: 15px 0;
                  }
                }
              `}
            >
              <Price>${p.price}</Price>
              <LinkLeafExt
                href={p.link}
                cssProp={css`
                  display: inline-flex;
                `}
              >
                Purchase
              </LinkLeafExt>
            </div>
          </ProductContainer>
        </>
      ))}
    </Container>
  );
};
