import React, { useState } from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Global, css } from "@emotion/core";
import { mediaQueries } from "../../configs/_configSite";

// Render a YouTube video player
function VideoPopup({ url, image }) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const onClick = () => {
    if (!isFullscreen) {
      setIsFullscreen(true);
    }
  };
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        css={css`
          position: absolute;
          bottom: 14rem;
          left: 2.8rem;
          padding: 0;
          margin: 0;
          cursor: pointer;
          height: 200px;
          width: 350px;
          display: block;
          z-index: 2001;
          img {
            display: block;
          }

          @media (max-width: ${mediaQueries.md}px) {
            display: none;
          }
        `}
      >
        <img
          css={css`
            width: 100%;
            height: 100%;
          `}
          src={image}
          alt=""
        />
      </button>
      <Global
        styles={css`
          .ReactModal__Overlay {
            z-index: 3000;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.75) !important;
          }
          .ReactModal__Content {
            background-color: #222 !important;
            border: none !important;
            inset: auto !important;
            overflow: visible !important;
            > div {
              max-width: 140vh;
              width: 6400px !important;
              height: 70vh !important;
            }
          }
        `}
      />
      <Modal
        isOpen={isFullscreen}
        onRequestClose={() => setIsFullscreen(false)}
        onAfterClose={() => setIsFullscreen(false)}
      >
        <button
          type="button"
          onClick={() => setIsFullscreen(false)}
          css={css`
            position: absolute;
            top: -1rem;
            right: -1rem;
            height: 2rem;
            width: 2rem;
            font-size: 1.7rem;
            padding: 0;
            border: none;
            background-color: #fff;
            border-radius: 50%;
            color: #000;
            cursor: pointer;
          `}
        >
          &times;
        </button>
        <ReactPlayer
          autoplay
          config={{ file: { attributes: { autoplay: "true" } } }}
          url={url}
          onClick={onClick}
        />
      </Modal>
    </>
  );
}

VideoPopup.propTypes = {
  url: PropTypes.string,
  image: PropTypes.string
};
VideoPopup.defaultProps = {
  url: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
  image: "http://placehold.it/350x210"
};

export default VideoPopup;
